body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:  #007aff;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: blue;
}
p {
  margin: 0px;
}
a {
  text-decoration: none;
}
img {
  width: 100%;
}
input {
  text-transform: inherit;
}
.snack-bar {
  &.error .MuiSnackbarContent-root {
    background-color: red !important;
  }
  &.info .MuiSnackbarContent-root {
    background-color: #1976d2 !important;
  }
  &.warning .MuiSnackbarContent-root {
    background-color: #ffc107!important;
  }
  &.success .MuiSnackbarContent-root {
    background-color: #4caf50 !important;
  }
}

$gray-darkest: #1c1c1c;
$gray-dark: #4c4c4c;
$gray-light: #7c7c7c;
$gray-lightest: #acacac;
$border-gray: #dadce0;
$primary-color: #007aff;
$primary-light: #007aff10;
$warning-color: #ffcc00;
$warning-light: #ffcc0010;
$error-color: #ff3b30;
$error-light: #ff3b3010;
$success-color: #34c759;
$success-light: #34c75910;

@mixin border-bottom {
  border-bottom: 1px solid $border-gray;
}

@mixin flex {
  display: flex;
  align-items: center;
}
