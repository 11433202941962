.container {
  display: flex;
  align-items: center;

  .actionButton {
    margin: 15px;
  }

  .logo {
    display: flex;
    margin: 0px 10px;

    .psypack {
      display: flex;

      img {
        width: 110px;
      }
      &:after {
        content: 'BETA';
        font-size: 0.6rem;
      }
    }
  }
}
