.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0px;
  background-color: #f2f2f2;
  padding: 10px 0px;
  z-index: 3;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  .org_logo {
    width: auto;
    height: 35px;
    padding: 10px;

    @media only screen and (max-width: 500px) {
      height: 32px;
    }
  }

}
